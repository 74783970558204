import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environment';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import * as Hammer from 'hammerjs';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from "ngx-spinner";
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './common/services/authentication/auth-guard.service';
import { HttpConfigInterceptor } from './common/services/http-interceptors/http-config-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { OverlayComponent } from './common/overlay/overlay.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { AbTestModule } from './common/ab-tests/ab-test.module';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    IvyCarouselModule,
    NgxSpinnerModule,
    HammerModule,
    SwiperModule,
    ToastrModule.forRoot(),
    NgbModule,
    PinchZoomModule,
    AbTestModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CookieService,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig,
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [OverlayComponent],
})
export class AppModule { }
