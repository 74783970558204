import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';
import { AbTestsService } from 'angular-ab-tests';
import { MixpanelService } from '../mixpanel/mixpanel.service';

@Injectable({
  providedIn: 'root'
})

export class UtilityService {
  pagesStaticHeader: any = ['/home', '/design', '/product', '/about', '/favorites']
  tooManyRequestStatusCode = 429;
  designToolUrl: string = environment.designToolUrl;

  designToolChangesAbTestingFlag = new BehaviorSubject(false);

  constructor(public router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private cookieService: CookieService,
    private abTestSvc: AbTestsService,
    private mixpanelSvc: MixpanelService
  ) {
    window.addEventListener('scroll', () => {
      if (this.pagesStaticHeader.some((page: string) => this.router.url.split('?')[0] === page) || this.router.url.includes('favorites-page')) {
        this.router.url.split('?')[0]
        let desktopHeader = document.getElementById("desktop-header");
        let mobileHeader = document.getElementById("header-mobile");
        window.onscroll = function (e) {
          if (desktopHeader) {
            if (scrollY > 0) {
              desktopHeader.classList.add("sticky-header");
            } else {
              desktopHeader.classList.remove("sticky-header");
            }
          }

          if (mobileHeader) {
            if(scrollY > 0) {
              mobileHeader.classList.add("sticky-header");
            } else {
              mobileHeader.classList.remove("sticky-header");
            }
          }

          this.oldScroll = this.scrollY;
        }
      }
    }, { passive: true, once: true });

    window.addEventListener('scroll', () => {
      const header = document.getElementById("psp-mobile-header");

      if (header) {
        if (window.pageYOffset > 80) {
          header.classList.add("mobile-sticky-header");
        } else {
          header.classList.remove("mobile-sticky-header");
        }
      }
    });
  }

  /**
   *
   * @param tags list of all tags
   * @param tagName the tagname followed by :
   * Scours through the tags to find the tag that's
   * referenced on shopify
   * object returned by algolia
   */
  public getSpecificTagFromAlgolia(tags: any, tagName: string) {
    for (let tag of tags) {

      if (tag.includes(tagName)) {
        let tagValue = tagName === 'lookbook_url:' ? tag.split(':')[1] + ':' + tag.split(':')[2] : tag.split(':')[1];

        return tagValue;
      }

    }
  }

  public capitalizeFirstChar(word: string) {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return null;
    }
  }

  /**
   *
   * @param arr can be any object that has a JSON array with
   * id parameter
   */
  public removeDuplicatesById(arr: any) {
    const mapObj = {};

    arr.forEach(a => {
      mapObj[a.id] = a;
    })

    return Object.values(mapObj);
  }

  /**
   *
   * @param arr Contrary to the above function,
   * this takes a simple non JSON array and returns
   * only unique values
   */
  public removeDuplicatesFromArray(arr: Array<any>) {
    let unique = [...new Set(arr)];

    return unique;
  }

  /**
   *
   * @param arr Shuffles an array randomly
   * This function doesn't have the same randomness
   * probability, but it fits our usecase anyway
   */
  public shuffle(arr: Array<any>) {
    arr.sort(() => Math.random() - 0.5);

    return arr;
  }

  /**
   *
   * @param rgb Takes an rgb input
   * and converts it into a hex value
   */
  public rgbToHex(rgb: string) {
    var hex = Number(rgb).toString(16);

    if (hex.length < 2) {
      hex = "0" + hex;
    }

    return hex;
  };

  /**
  * @param hex String representing a hex color.
  * String starts with #
  */
  public hexToRgb(hex: string): any {
    let r, g, b;
    if (hex.length == 4) {
      r = +("0x" + hex[1] + hex[1]);
      g = +("0x" + hex[2] + hex[2]);
      b = +("0x" + hex[3] + hex[3]);
    } else if (hex.length == 7) {
      r = +("0x" + hex[1] + hex[2]);
      g = +("0x" + hex[3] + hex[4]);
      b = +("0x" + hex[5] + hex[6]);
    }

    return {
      r,
      g,
      b,
      string: `rgb(${r}, ${g}, ${b})`
    }
  }

  /**
  * @param r number 0-255
  * @param g number 0-255
  * @param b number 0-255
  */
  public rgbToHsl(r: number, g: number, b: number): any {
    r /= 255;
    g /= 255;
    b /= 255;

    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    if (delta == 0)
      h = 0;
    else if (cmax == r)
      h = ((g - b) / delta) % 6;
    else if (cmax == g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
      h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return {
      h,
      s,
      l,
      string: `hsl(${h}, ${s}%, ${l}%)`
    };
  }

  public createGradientBackgroundFromHex(startColor) {
    if (startColor) {
      const fallbackHue = 215;
      const { r, g, b } = this.hexToRgb(startColor);
      let { h } = this.rgbToHsl(r, g, b);

      h = Number(h) || fallbackHue;

      return {
        background: `transparent linear-gradient(180deg, hsl(${h}, 100%, 99%) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`
      };
    }
  }

  /**
   *
   * @param contentToCopy
   * Utility Service that copies text to clipboard
   */
  public copyToClipboard(contentToCopy: string) {
    let tempText = document.createElement('textarea');
    tempText.style.position = 'fixed';
    tempText.style.left = '0';
    tempText.style.top = '0';
    tempText.style.opacity = '0';
    tempText.value = contentToCopy;
    document.body.appendChild(tempText);
    tempText.focus();
    tempText.select();
    document.execCommand('copy');
    document.body.removeChild(tempText);
  }

  public getCurrentTopOffset() {
    var screenTop = $(document).scrollTop();

    return screenTop;
  }

  /**
 * Converts Text to Image
 * @param textData
 */

  public createTextToImage(textData: string, canvas: any, type: string) {
    //  let canvas = this.canvasval.nativeElement;
    let ctx = canvas.getContext("2d");
    let maxWidth = 129;
    let words = textData.split(' ');
    let line = '';
    let x = (ctx.canvas.width / maxWidth) / 2;
    let y = 10;
    let lineHeight = 15;


    ctx.canvas.width = 140;
    ctx.canvas.height = 90;

    if (type && type == 'designDesc') {
      maxWidth = 50;
      lineHeight = 10;
      ctx.canvas.width = 40;
      ctx.canvas.height = 40;
    } else if (type && type == 'locationDesc') {
      maxWidth = 50;
      lineHeight = 10;
      ctx.canvas.width = 140;
      ctx.canvas.height = 120;
    };

    ctx.font = "11px Lato Regular";
    ctx.fillStyle = "#8B8B8B";

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + ' ';
      var metrics = ctx.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        ctx.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      }
      else {
        line = testLine;
      }
    }
    ctx.fillText(line, x, y);
    return ctx.canvas.toDataURL();
  }


  /**
   *
   * @param blur true or false
   * Utility Service to blur whatever elements
   * need to blurred.
   * Keep adding new elements as you want.
   * This the potential to be heavily parametrized,
   * but not in the near future
   */
  public blurBackground(blur: boolean) {
    let headerMobileEl = document.getElementById("header-mobile");
    let headerDesktop = document.getElementById("desktop-header");

    let homepageEl = document.getElementById("homepage-container");

    let galleryDesktopEl = document.getElementById("gallery-desktop");

    let gridLayoutMobileEl = document.getElementById("design-gallery-mobile");
    let gridLayoutDesktopEl = document.getElementById("design-gallery-desktop");

    let productGalleryDesktopEl = document.getElementById("product-gallery-desktop");

    let filterMobileEl = document.getElementById("common-filter");
    let galleryContainerEl = document.getElementById("gallery-container");

    let footerEl = document.getElementById("footer-container");
    let footerLinesEl = document.getElementById("footer-bottom-color");

    let thankYouPage = document.getElementById('thank-you-page');

    let favoritesPage = document.getElementById('user-favorite-page');

    let userLoginWrapper = document.getElementById('user-login-wrapper-id');
    let userSignUpWrapper = document.getElementById('user-signup-wrapper');
    let forgotPasswordWrapper = document.getElementById('forgot-password');


    if (blur) {
      this.document.body.style.overflow = 'auto';
      this.document.body.style.backgroundColor = '#C0C0BD';
      homepageEl ? homepageEl.classList.add("blur-and-deactivate") : null;

      gridLayoutMobileEl ? gridLayoutMobileEl.classList.add("blur-and-deactivate") : null;
      gridLayoutDesktopEl ? gridLayoutDesktopEl.classList.add("blur-and-deactivate") : null;

      galleryDesktopEl ? galleryDesktopEl.classList.add("blur-and-deactivate") : null;

      productGalleryDesktopEl ? productGalleryDesktopEl.classList.add("blur-and-deactivate") : null;

      filterMobileEl ? filterMobileEl.classList.add("blur-and-deactivate") : null;
      headerMobileEl ? headerMobileEl.classList.add("blur-and-deactivate") : null;
      headerDesktop ? headerDesktop.classList.add("blur-and-deactivate") : null;
      footerEl ? footerEl.classList.add("blur-and-deactivate") : null;
      footerLinesEl ? footerLinesEl.classList.add("blur-and-deactivate") : null;
      thankYouPage ? thankYouPage.classList.add("blur-and-deactivate") : null;
      favoritesPage ? favoritesPage.classList.add("blur-and-deactivate") : null;
      userLoginWrapper ? userLoginWrapper.classList.add("blur-and-deactivate") : null;
      userSignUpWrapper ? userSignUpWrapper.classList.add("blur-and-deactivate") : null;
      forgotPasswordWrapper ? forgotPasswordWrapper.classList.add("blur-and-deactivate") : null;

    } else {
      this.document.body.style.overflow = 'auto';
      this.document.body.style.backgroundColor = 'white';
      homepageEl ? homepageEl.classList.remove("blur-and-deactivate") : null;

      gridLayoutMobileEl ? gridLayoutMobileEl.classList.remove("blur-and-deactivate") : null;
      gridLayoutDesktopEl ? gridLayoutDesktopEl.classList.remove("blur-and-deactivate") : null;

      galleryDesktopEl ? galleryDesktopEl.classList.remove("blur-and-deactivate") : null;

      productGalleryDesktopEl ? productGalleryDesktopEl.classList.remove("blur-and-deactivate") : null;

      filterMobileEl ? filterMobileEl.classList.remove("blur-and-deactivate") : null;
      headerMobileEl ? headerMobileEl.classList.remove("blur-and-deactivate") : null;
      headerDesktop ? headerDesktop.classList.remove("blur-and-deactivate") : null;

      footerEl ? footerEl.classList.remove("blur-and-deactivate") : null;
      footerLinesEl ? footerLinesEl.classList.remove("blur-and-deactivate") : null;
      thankYouPage ? thankYouPage.classList.remove("blur-and-deactivate") : null;
      favoritesPage ? favoritesPage.classList.remove("blur-and-deactivate") : null;
      userLoginWrapper ? userLoginWrapper.classList.remove("blur-and-deactivate") : null;
      userSignUpWrapper ? userSignUpWrapper.classList.remove("blur-and-deactivate") : null;
      forgotPasswordWrapper ? forgotPasswordWrapper.classList.remove("blur-and-deactivate") : null;
    }
  }

  /** Method to Change the BG Color when the Internal Design or Product Gallery is Open
   * @param color Color of the BG Color
   * @param blur boolean to determine the BG Color change
   */
  public changeBGConversionFunnel(color, blur) {
    let funnelEl = document.getElementById("expand-section");

    if(funnelEl) {
      if(blur && color) {
        funnelEl.style.backgroundColor = color;
      } else {
        funnelEl.style.backgroundColor = '#ffffff'
      }
    }
  }

  /** Method to blur the BG of the funnel when any oof the section is expanded in location step
   * @param blur boolean to determine the BG Blur
   */
  public blurFunnelBG(blur) {
    let mobileBody = document.getElementById("mobileBody");

    if (blur) {
      mobileBody ? mobileBody.classList.add('remove-scroll') : null
    } else {
      mobileBody ? mobileBody.classList.remove('remove-scroll') : null
    }
  }

  /**
   * Returns front location if present
   * @param selectedColor selected color JSON
   */
  public findFrontLocation(selectedColor: any) {
    let frontLocationIndex = selectedColor.variants.findIndex((variant: any) => {
      return variant.location.toUpperCase() == "FRONT";
    });

    return frontLocationIndex;
  }

  /**
   * Converts a number into a string
   * with commas in the thousand's place
   */
  public numberWithCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /**
   * Remove delimiter and add a line break
   */
  public breakDescriptionIntoLines(description: string, delimiter: string) {
    let descriptionLines = description.split(delimiter);
    let processedDescription = "";

    for (let line of descriptionLines) {
      processedDescription = processedDescription.length == 0 ? line : processedDescription + " <br> " + line;
    }

    return processedDescription;
  }

  public setOffSetToCarousel(containerId: string, offsetValue: string, delayInMs: number) {
    setTimeout(function () {
      $(containerId).find(".owl-stage").css('transform', 'translate3d(' + offsetValue + ', 0px, 0px)');
    }, delayInMs);
  }

  public createQueryParams(recevingValues: any, callerType: any) {
    let filterTags = '';
    let subFilterTags = '';
    let isColorSelected = false;
    let isFilterSelected = false;
    let baseTag = "tags:content_type:designs_newfp.com"
    if (callerType == 'product') {
      baseTag = "tags:content_type:products_newfp.com";
    }
    for (let receivedFilters of recevingValues.filters) {
      let subData = receivedFilters;
      for (let dataOfFilter of subData) {
        if (dataOfFilter != baseTag) {
          let tags = dataOfFilter.split(":");
          if (tags[0] != 'options.hex') {
            if (filterTags.length > 0) {
              filterTags = filterTags + "," + tags[1];
              subFilterTags = subFilterTags + "," + tags[2];

            } else {
              filterTags = tags[1];
              subFilterTags = tags[2];
            }
            isFilterSelected = true;
          }
        }
      }
    }
    let queryParam = {};
    let selecteColorGroup = "";
    let selecteMainColorGroup = "";
    if (recevingValues.selectedColor) {
      selecteColorGroup = recevingValues.selectedColor.hex ? recevingValues.selectedColor.hex : null;
      selecteMainColorGroup = recevingValues.selectedColor.majorColorHex;

      isColorSelected = true;
    }
    if (isFilterSelected) {
      queryParam["mainFilterTag"] = filterTags;
      queryParam["subFilter"] = subFilterTags;
    }
    if (isColorSelected) {
      queryParam["colorGroup"] = selecteColorGroup;
      queryParam["mainColorGroup"] = selecteMainColorGroup;
    }
    if (recevingValues.searchQuery.length > 0) {
      queryParam["searchTag"] = recevingValues.searchQuery;
    }
    return queryParam;
  }

  /**
   *
   * @param searchFilter search data from the filters
   * @param colorFilter selected hex codes; ex: "#000102,#000000"
   * @param colorGroup sub color group; ex: "#000102"
   * @param mainColorGroup main color group; ex: "#000000"
   * @param mainFilterTag filter type; ex: "productType"
   * @param subFilter selected filter from the type; ex: "shirts"
   * @param filterList the base filterList array; ex: ["tags:content_type:products_newfp.com"]
   */
  public generateFiltersFromUrl(searchFilter:any, colorFilter:any, colorGroup:any, mainColorGroup:any, mainFilterTag:any, subFilter: any, filterList: any) {
    let listOfSelectedFilters: any = [];
    let searchQuery = "";
    let specificColorsToFetch = null;

    if (searchFilter) {
        searchQuery = searchFilter;

        let selectedFilters = {
          mainFilterTag: "Search",
          subFilter: searchFilter
        }

        listOfSelectedFilters.push(selectedFilters);
    }

    if (colorFilter) {
      let colorFilterArray = [];

      specificColorsToFetch = colorFilter.split(",");

      for (var counter: number = 0; counter < specificColorsToFetch.length; counter++) {
        let constructedFilter = "options.hex:" + specificColorsToFetch[counter];

        colorFilterArray.push(constructedFilter);
      }

      filterList.push(colorFilterArray);

      let colorDetails = {
        mainColorGroup : mainColorGroup,
        colorGroup: colorGroup,
        colorFilter: colorFilter
      }

      let selectedFilters = {
        mainFilterTag: "colorGroup",
        subFilter: colorDetails
      }

      listOfSelectedFilters.push(selectedFilters);
    }

    if (mainFilterTag && subFilter) {
      let mainFilterTagsArray = mainFilterTag.split(',');
      let subFiltersArray = subFilter.split(',');

      for (let counter = 0; counter < mainFilterTagsArray.length; counter++) {
        let constructedFilterString = "tags:" + mainFilterTagsArray[counter] + ":" + subFiltersArray[counter];

        let sameTagIndex = filterList.findIndex((filterItem: any) => {
          return JSON.stringify(filterItem).includes("tags:" + mainFilterTagsArray[counter]);
        })

        if (sameTagIndex >= 0) {
          filterList[sameTagIndex].push(constructedFilterString);
        } else {
          filterList.push([constructedFilterString]);
        }

        let selectedFilters = {
          "mainFilterTag": mainFilterTagsArray[counter],
          "subFilter": subFiltersArray[counter]
        }

        listOfSelectedFilters.push(selectedFilters);
      }
    }

    return [listOfSelectedFilters, searchQuery, specificColorsToFetch];
  }

  public getParameter(theParameter: string) {
    var params = window.location.search.substr(1).split('&');
    for (var i = 0; i < params.length; i++) {
      var p = params[i].split('=');

      if (p[0] == theParameter) {
        return decodeURIComponent(p[1]);
      }
    }

    return null;
  }

  /**
   * A classic window scroll function.
   * Imagine you're controlling the browser scroller.
   * @param x x coordinate.
   * @param y y coordinate.
   */
  public windowScrollTo(x: number, y: number) {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(x, y);
    }
  }

  /**
   * Smooth scroll using the smoothscroll-polyfill lib.
   * @param top value of where you wanna scroll.
   */
  public windowSmoothScrollTo(top: number) {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({ top: top, behavior: 'smooth' });
    }
  }

  public parseUserType(accessLevel: number) {
    let userTypeMap = {
      "1": "Admin",
      "2": "Manager",
      "3": "Artist",
      "4": "Customer",
      "5": "Printer",
    }

    return userTypeMap[accessLevel.toString()]
  }

  /**
   *
   * @param media
   * @param textToShare
   * @param shareableUrl
   * Utility Service share the url on social media
   */

  public shareOnSocialMedia(media: string, textToShare: string, shareableUrl: string) {
    switch (media) {
      case "instagram": {
        let twitterSharingURL = `https://www.instagram.com/shirtjustgotcrazy/?text=${textToShare}&url=${shareableUrl}&via=FreshPrintsLLC`
        window.open(twitterSharingURL, "TwitterShare", 'width=700,height=500');
        break;
      }

      case "facebook": {
        let facebookSharerUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareableUrl}&t=${textToShare}`;
        window.open(facebookSharerUrl, "TwitterShare", 'width=700,height=500');
        break;
      }
    }
  }

  /**
   *
   * @param event
   * Utility Service method to prevent the input filed from taking non numerical values
   */

  public preventNonNumericalInput(event) {
    event = event || window.event;
    const charCode = (typeof event.which === 'undefined') ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/)) {
      event.preventDefault();
    }
  }

  /**
   * Utility Service method to prevent the input filed from taking numerical values
   * @param event
  */
   public preventNumericalInput(event) {
    event = event || window.event;
    const charCode = (typeof event.which === 'undefined') ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[a-zA-Z ]+$/)) {
      event.preventDefault();
    }
  }

  /** Method to return the Date in YYYY:MM:DD format*/
  public formattedDate() {
    let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  /* This method take string as parameter and convert the string into titleCase
   * @param str : any string
   * @returns title case transformation of that string
  */
  public toTitleCase(str) {
    if(!str)
      return ''

    return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  /** Method to return the UTM data only when the user selects*/
  getUtmData() {
    const utmData = {}

    if(this.cookieService.get('utm_source')){
      utmData['utm_source'] = this.cookieService.get('utm_source');
    }

    if(this.cookieService.get('utm_medium')){
      utmData['utm_medium'] = this.cookieService.get('utm_medium');
    }

    if(this.cookieService.get('utm_campaign')){
      utmData['utm_campaign'] = this.cookieService.get('utm_campaign');
    }

    if(this.cookieService.get('utm_content')){
      utmData['utm_content'] = this.cookieService.get('utm_content');
    }

    if(this.cookieService.get('utm_term')){
      utmData['utm_term'] = this.cookieService.get('utm_term');
    }

    return utmData
  }

  /**
   * Method to return the organization type.
   * @param organizationType (1 || 2 || 3 || 4 || 5 || 6 )
   */
  getOrganizationType(organizationType: number) {
    let orgTypeMap = {
      1: "Sorority",
      2: "Fraternity"
    }

    return orgTypeMap[organizationType]
  }

  /** This method returns the Operating system of the Mobile */
  getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor;

    /** Windows Phone must come first because its UA also contains "Android" */
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    } else if (/android/i.test(userAgent)) {
        return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  /**
   * Method to initialize animate any block on scrolling
   * @param className class name
   * @param splide Active spilde
   */
  initializeIntersecting(className, splide) {
    const sliders = document.querySelectorAll(className);

    const appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -250px 0px"
    };

    const appearOnScroll = new IntersectionObserver(function(
      entries,
      appearOnScroll
    ) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add("appear");
          appearOnScroll.unobserve(entry.target);
          setTimeout(() => {
            splide.mount();
          });
        }
      });
    },
    appearOptions);

    sliders.forEach(slider => {
      appearOnScroll.observe(slider);
    });
  }

  /** This method is to split the firtName and lastName from fullName and remove
   * @param userName the name of the user to be split
  */
  public splitUserName(userName) {
    let fullName = userName.trim();
    let firstName = "";
    let lastName = "";
    /** Updated the logic of first name and last name to store in db */
    firstName = fullName.split(" ")[0];
    let index = fullName.indexOf(" ");
    lastName = fullName.substring(index + 1);

    let name = {
      firstName: firstName,
      lastName: lastName,
    };

    return name;
  }

  /**
   * Method to capitalize the first letter of a string.
   * @param value String to be modified.
   */
  capitalizeFirstLetter(value) {
    if (value) {
      const data = String(value);
      const str = data.charAt(0).toUpperCase() + data.slice(1);
      return str;
    }
  }

  /**
   * Funtion returns the color id of the particular color.
   */
  trackByColorId(color){
    return color.id;
  }

  /**
 * Funtion returns the index for *ngFor directive.
 */
    trackByIndex(index){
    return index;
  }

  /**
   * Funtion returns the unique index of mainDesignsListIndex.
   */
  mainDesignsListIndex(index){
    return index;
  }

  /**
   * Funtion returns the unique index of DesignsListIndex.
   */
  DesignsListIndex(index){
    return index;
  }

  /**
   * Funtion returns the unique index of trackBymainDesignsListIndex.
   */
  trackBymainDesignsListIndex(index,design){
    return design.title;
  }

  /**
   * Funtion returns the unique index of mainProductsListIndex.
   */
  mainProductsListIndex(index){
    return index;
  }

  /**
  * Function returns the slide image of trackByImage.
  */
  trackByImage(index, slide){
    return slide.image;
  }

    /**
 * Funtion returns the unique index of mainFilters.
 */
     mainFilters(index){
      return index;
    }

    /**
   * Funtion returns the unique index of filterItems.
   */
     filterItems(index){
      return index;
    }

    /**
   * Funtion returns the unique index of selectedFilterPills.
   */
     selectedFilterPills(index){
      return index;
    }

    /**
   * Funtion returns the unique index of commonSelectedFilterPills.
   */
     commonSelectedFilterPills(index){
      return index;
    }

    /**
   * Funtion returns the unique index of mainFiltersItems.
   */
     mainFiltersItems(index){
      return index;
    }

    /**
   * Funtion returns the unique index of showFilterItems.
   */
     showFilterItems(index){
      return index;
    }

    /**
   * Funtion returns the unique index of majorColor.
   */
     majorColor(index){
      return index;
    }

    /**
   * Funtion returns the unique index of ItemsMajorColor.
   */
     itemsMajorColor(index){
      return index;
    }

      /**
   * Design specific page trackby functions
   */

  /**
   * Funtion returns the unique index of imageList.
   */
   imageList(index){
    return index;
  }

  /**
   * Funtion returns the unique index of tagsToDisplay.
   */
   tagsToDisplay(index){
    return index;
  }

  /**
   * Funtion returns the unique index of productsPerfectForDesign.
   */
   productsPerfectForDesign(index){
    return index;
  }

  /**
   * Funtion returns the unique index of yellowStarRatingArray.
   */
   yellowStarRatingArray(index){
    return index;
  }

  /**
   * Funtion returns the unique index of halfStarRatingArray.
   */
   halfStarRatingArray(index){
    return index;
  }

  /**
   * Funtion returns the unique index of grayStarRatingArray.
   */
   grayStarRatingArray(index){
    return index;
  }

  /**
   * Funtion returns the unique index of productColors.
   */
   productColors(index){
    return index;
  }

  /**
   * Funtion returns the unique index of relatedDesigns.
   */
   relatedDesigns(index){
    return index;
  }

  /**
   * Funtion returns the unique index of productColorsContainer.
   */
    productColorsContainer(index){
    return index;
  }

  /**
   * Funtion returns the unique index of productColorsContainer.
   */
   relatedProducts(index){
    return index;
  }

  /**
   * Funtion returns the unique index of productColorsContainer.
   */
   coloredStarRatingArray(index){
    return index;
  }

  /**
   * Funtion returns the unique index of productColorsContainer.
   */
   productHalfStarRatingArray(index){
    return index;
  }

  /**
   * Funtion returns the unique index of productColorsContainer.
   */
   nonColoredStarRatingArray(index){
    return index;
  }

  /**
   * Function that returns the updation details with the specific time.
   *
   * @param createdAt - Location creation date
   * @param updatedAt - Location updation date
   */
  public getUpdatedTimeInfo(createdAt: any, updatedAt: any) {
    if (updatedAt) {
      const createdAtValue = createdAt ? new Date(createdAt) : null;
      const updatedAtValue = new Date(updatedAt);
      const currentDate = new Date();
      const daysHourObj: any = this.getDateTimeDifference(updatedAtValue, currentDate);
      const timeKey = Object.keys(daysHourObj)[0];

      if ((createdAtValue && createdAtValue.getTime()) === updatedAtValue.getTime()) {
        return `${daysHourObj[timeKey]} ${daysHourObj[timeKey] > 1 ? `${timeKey}s` : timeKey}`;
      } else {
        return `${daysHourObj[timeKey]} ${daysHourObj[timeKey] > 1 ? `${timeKey}s` : timeKey}`;
      }
    } else {
      return null;
    }
  }

  /**
   * Function that compares two dates and return the difference in days or hours or minutes.
   *
   * @param date1 - the first date
   * @param date2 - the second date
   */
  public getDateTimeDifference(date1: any, date2: any) {
    const days = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    const hours = Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60) % 24);
    const minutes = Math.floor(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 60) % 60);
    const seconds = Math.floor(Math.abs(date2.getTime() - date1.getTime()) / (1000) % 60);

    if (days) {
      return { day: days };
    } else if (hours) {
      return { hr: hours };
    } else if (minutes) {
      return { min: minutes };
    } else {
      return { sec: seconds };
    }
  }

  /**
   * Method to return the short format of the user name.
   *
   * @param userName - the user name
  */
  public getUserName(userName: string) {
    const splittedUser = userName.split(' ');

    if (splittedUser.length && splittedUser.length === 1) {
      return splittedUser[0].charAt(0).toUpperCase();
    } else {
      return `${splittedUser[0].charAt(0).toUpperCase()}${splittedUser[1].charAt(0).toUpperCase()}`;
    }
  }

  /**
   * Method to find the ripple element from the DOM and add ripple dimensions into it.
   */
  public attachRippleEffect() {
    setTimeout(() => {
      const buttons = document.querySelectorAll('.ripple-btn');

      buttons.forEach(btn => {
        btn.addEventListener('click', function (e:any) {
          /** Need these values in future versions.*/
          let x = e.clientX - e.target.offsetLeft;
          let y = e.clientY - e.target.offsetTop;

          let ripples = document.createElement('span');
          ripples.style.left = '50%';
          ripples.style.top = '50%';
          this.appendChild(ripples);

          window.setTimeout(() => {
            ripples.remove();
          }, 600);
        })
      });
    }, 100);
  }

  /**
   * Method to add ripple dimensions to the active element.
   *
   * @param rippleEle - Current element.
   * @param e - Click or Touch event.
   */
  public addRippleDimensions(rippleEle: any, e: any) {
    e = e.touches ? e.touches[0] : e;

    const clientRect = rippleEle.getBoundingClientRect();
    const path = Math.sqrt(Math.pow(clientRect.width,2) + Math.pow(clientRect.height,2)) * 2;

    rippleEle.style.cssText = `--s: 0; --o: 1;`;  rippleEle.offsetTop;
    rippleEle.style.cssText = `--t: 1; --o: 0; --d: ${path}; --x:${e.clientX - clientRect.left}; --y:${e.clientY - clientRect.top};`
  }

  /**
   * Method to return initials from full name.
   *
   * @param fullName - CM name.
   */
  public getInitialsFromName(fullName: string) {
    if (fullName) {
      const namesArr = fullName.split(' ');

      return namesArr[0].charAt(0) + namesArr[1].charAt(0);
    }
  }

  /**
   * Method to shuffle the elements of an array.
   *
   * @param array - Array of elements.
   * @param numberOfElements - Number of elements to be shuffled.
   */
  public shuffleArray(array: any[], numberOfElements: number) {
    let currentIndex = numberOfElements;

    while (0 !== currentIndex) {
      let randomIndex = Math.floor(Math.random() * currentIndex);

      currentIndex -= 1;
      let temporaryValue = array[currentIndex];

      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  /**  Helper function to serialize query parameters **/
  public serializeParams(params): string {
    return Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
  }

  public getSpecificProductTagFromShopify(tags: any, tagName: string) {
    if (tags && tags.length) {
      for (let tag of tags) {
        if (tag.includes(tagName)) {
          let tagValue = tag.split(':')[1];

          return tagValue;
        }
      }
    }
  }

  public getEncodedUrl(url: string) {
    return encodeURIComponent(url);
  }

  private getHexCode(color: string) {
    const hexCode = color.split(":")[1];
    const colorHexCode = hexCode ? hexCode.startsWith("#") ? hexCode : `#${hexCode}` : null;

    return colorHexCode;
  }

  public getProductAssociatedDesigns(design: any) {
    if (design && design.meta && design.meta.design && design.meta.design.product_color) {
      const hexCode = this.getHexCode(design.meta.design.product_color);

      if (hexCode) {
        return `${this.designToolUrl}?designId=${design.id}&styleCode=${design.associatedProductStylecode}&colorHex=${this.getEncodedUrl(`${hexCode}`)}`;
      }

      return `${this.designToolUrl}?designId=${design.id}&styleCode=${design.associatedProductStylecode}`;
    } else {
      return `${this.designToolUrl}?designId=${design.id}&styleCode=${design.associatedProductStylecode}`;
    }
  }

  public getDTVersion () {
    return  'Accessible';
  }
  
  /**
   * Method to redirect to the design tool.
   */
  public redirectToDesignTool(page, data) {
  
    let url = this.designToolUrl;
    if (page === 'product') {
      const brand = this.getSpecificProductTagFromShopify(data.tags, 'brand:');
      const queryParams = `styleCode=${data.styleCode}&colorHex=${this.getEncodedUrl(data.selectedColorHex)}`;
      url = brand ? `${this.designToolUrl}?${queryParams}&brand=${brand}` : `${this.designToolUrl}?${queryParams}`;
      this.mixpanelSvc.track("Customized via DT");
    } else if(page === 'design') {
      url = this.getProductAssociatedDesigns(data);
      this.mixpanelSvc.track("Customized via DT");
    }
    // Append UTM parameters if any exist
    let utlWithUTMS = this.appendUTMStoURL(url);
    window.open(utlWithUTMS, '_self');
   }
  

  /** Method to test whether the design is supported by DT or not. */
  public checkTheDesignToolDesign(data) {
    if (data && data.meta && Object.keys(data.meta).length) {
      return data.meta.design?.design_tool ? true : false;
    } else {
      return false;
    }
  }

  /** Method to test whether the product is supported by DT or not. */
  public checkTheDesignToolProduct(data) {
    if (data && data.meta && Object.keys(data.meta).length) {
      return data.meta.product?.designtool === "true" || data.meta.product?.designtool === true ? true : false;
    } else {
      return false;
    }
  }

  public appendUTMStoURL(url) {
    /**
      * We should grab the marketing campaign UTMS and forward them to design tool
    */
    let newURLWithUTMS = url;
    const utmParams: string[] = [];
    let utmData = this.getUtmData();

    utmData['utm_source'] && utmParams.push(`utm_source=${utmData['utm_source']}`);
    utmData['utm_medium'] && utmParams.push(`utm_medium=${utmData['utm_medium']}`);
    utmData['utm_campaign'] && utmParams.push(`utm_campaign=${utmData['utm_campaign']}`);
    utmData['utm_content'] && utmParams.push(`utm_content=${utmData['utm_content']}`);
    utmData['utm_term'] && utmParams.push(`utm_term=${utmData['utm_term']}`);
    // Append UTM parameters if any exist
    if (utmParams.length > 0) {
      const utmQueryString = utmParams.join('&');
      newURLWithUTMS += (newURLWithUTMS.includes('?') ? '&' : '?') + utmQueryString;
    }
    return newURLWithUTMS;
  }
}
