import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MixpanelService } from '../mixpanel/mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loggedInUserId: number;
  USER_ERROR_MESSAGE = 'Unauthorized Request';
  getLoggedUserInfo = new BehaviorSubject('');
  loggedUserDetails = new BehaviorSubject(null);
  userId = '';
  userDetails = null;
  featureSwitches: any = null;
  canAccessDesignTool = new BehaviorSubject('error');
  userCanAccessDesignTool = 'error';

  constructor(private http: HttpClient, private mixpanelSvc: MixpanelService) {
    this.getLoggedUserInfo.subscribe((res) => {
      this.loggedInUserId = typeof res === 'number' ? res : null;
    });
  }

  /**
   * Method to set the logged in user details to the observable
   * @param response logged in user data
   */
  setLoggedInUserDetails(response) {
    this.getLoggedUserInfo.next(response);
  }

  /** Method to make an API call to DB to get the current user details (user_id)*/
  getLoggedInUserDetails() {
    return this.http.get<any>(environment.adminV3Url + 'api/fp/getCurrentUser').pipe(
      map(response => {
        /** This is to identify the currently logged in users upon a browser reload */
        if (response.status ==='success' && response?.data?.user?.email) {
          this.mixpanelSvc.identify(response.data.user.email);
        }

        return response;
      })
    );
  }

   /** Method to make an API call to let the user log in to FP. Once the user successfully logs in session will be created
    * @param data User email and password.
   */
  login(data):  Observable<any> {
    return this.http.post<any>(`${environment.adminV3Url}api/fp/login`, data);
  }

  /**
   * Method to set the logged in user details to the observable once the user log in from the modal.
   * @param data logged in user details
   */
  setLoggedUserDetails(data) {
    this.loggedUserDetails.next(data);
  }

  /** Returns logged in user id */
  getLoggedInUserId(): any {
    return this.loggedInUserId;
  }

  setFeatureSwitches(res) {
    this.featureSwitches = res;
  }

  getFeatureSwitches(): Observable<any> {
    return this.http.get<any>(`${environment.adminV3Url}api/fp/feature-switches`);
  }

  setCanAccessDt(res) {
    this.canAccessDesignTool.next(res);
  }
}
