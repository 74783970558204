import { NgModule } from '@angular/core';
import { AbTestsModule, AbTestOptions } from 'angular-ab-tests';
 
export const abTestsOptions: AbTestOptions[] = [
  {
    versions: ['A','B'],
    scope: 'MGO-changes-test',
    weights: {"A": 99.99} /** If I keep the 100% for A version, AB tests will raise an exception. So the value should be as close as possible to the 100%*/
  },
  {
    versions: [ 'A', 'B' ],
    scope: 'DT-CHANGES-TEST',
    weights: {"A": 99.99}
  },
];
 
@NgModule({
  imports: [
    AbTestsModule.forRoot(abTestsOptions),
  ],
})
export class AbTestModule {}