import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthenticationService, private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<any> {
    return this.authService.getLoggedInUserDetails().pipe(
      map(res => {
        if (res['Error']) {
          return false;
        } else {
          return true;
        }
      }),
      catchError((err) => {
        this.router.navigate(['home']);
        return of(false);
      })
    );
  }

}
