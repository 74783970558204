export const environment = {
  env: 'PROD',
  production: true,
  googleAnalyticsDevMode : true,

  serverBaseUrl: 'https://nidavellir.freshprints.com/',
  serverUrl: 'https://nidavellir.freshprints.com/api/',
  authToken: 'Basic ZnBhZG1pbjpmcHNlY3JldA==',

  appUrl: "http://freshprints.com/",
  adminV3Url: 'https://v3.freshprints.com/',
  adminV3DashboardUrl: 'https://v3.freshprints.com/dashboard/',
  mixpanelToken : "e0b7eec84f2b76dec645ecbf72e1b3f3",
  customerPortalUrl: 'https://hq.freshprints.com/',
  designToolUrl: 'https://design-tool.freshprints.com',

  algolia: {
    baseUrl: 'https://VJKL4FN2UO-dsn.algolia.net/1/indexes/production_shopify_products',
    apiKey: '207158545820498c16bca3555fd94f8a',
    appId: 'VJKL4FN2UO',
    index: 'production_shopify_products'
  },

  shopify: {
    url: 'https://freshprintstest.myshopify.com/api/graphql',
    shopifyAccessTtoken: '2bc5146b5965420c1ac6226b7c730baf'
  },

  iterableConfig : {
    apiKey     : "b7a0ff38e0dd4170a4dcb241589e1071",
    eventUrl   : "https://api.iterable.com/api/events/track",
    userUrl    : "https://api.iterable.com/api/users/update"
  },

  cloudImageCdn: "https://alofmzptzp.cloudimg.io/v7/",
  
  httpCall:{
    authDetails: 'Basic ZnBhZG1pbjpmcHNlY3JldA=='
  }
};
