import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot, PreloadAllModules } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  // Change this to homepage after building it.
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import(`./homepage/homepage.module`).then(m => m.HomepageModule) },
  { path: 'designs', loadChildren: () => import(`./design-gallery/design-gallery.module`).then(m => m.DesignGalleryModule), runGuardsAndResolvers: "always" },
  { path: 'design', loadChildren: () => import(`./design-specific/design-specific.module`).then(m => m.DesignSpecificModule) },
  { path: 'createproof', loadChildren: () => import(`./conversion-funnels/conversion-funnels.module`).then(m => m.ConversionFunnelsModule) },
  { path: 'products', loadChildren: () => import(`./product-gallery/product-gallery.module`).then(m => m.ProductGalleryModule) },
  { path: 'product', loadChildren: () => import(`./product-specific/product-specific.module`).then(m => m.ProductSpecificModule) },
  { path: 'group-order', loadChildren: () => import(`./group-order/group-order.module`).then(m => m.GroupOrderModule) },
  { path: 'favorites', loadChildren: () => import(`./favorite-page/favorite-page.module`).then(m => m.FavoritePageModule) },
  { path: 'favorites-page/:id', loadChildren: () => import(`./favorite-page/favorite-page.module`).then(m => m.FavoritePageModule) },
  { path: 'login', loadChildren: () => import(`./login-page/login-page.module`).then(m => m.LoginPageModule) },
  { path: 'signup', loadChildren: () => import(`./signup-page/signup-page.module`).then(m => m.SignupPageModule) },
  { path: 'reset-password', loadChildren: () => import(`./reset-password/reset-password.module`).then(m => m.ResetPasswordModule) },
  { path: 'tracking', loadChildren: () => import(`./tracking-page/tracking-page.module`).then(m => m.TrackingPageModule) },
  /**
   * Subpages
   */
  { path: 'about', loadChildren: () => import(`./subpages/about-us/about-us.module`).then(m => m.AboutUsModule) },
  { path: 'legal', loadChildren: () => import(`./subpages/legal/legal.module`).then(m => m.LegalModule) },
  { path: 'terms', loadChildren: () => import(`./subpages/legal/legal.module`).then(m => m.LegalModule) },
  { path: '**', loadChildren: () => import(`./subpages/page-not-found/page-not-found.module`).then(m => m.PageNotFoundModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", initialNavigation: 'enabled', scrollPositionRestoration: 'enabled'}), HttpClientModule],
  providers: [ { provide: 'externalLinkResolver', 
    useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        { window.location.href = (route.data as any).externalUrl; } } ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
