import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { productFilters } from 'src/app/common/constants';

@Injectable({
  providedIn: 'root'
})
export class ProductGalleryService {

  private productsListSource = new BehaviorSubject(null);
  currentProductsList = this.productsListSource.asObservable();

  private resetProductsSource = new BehaviorSubject(null);
  resetProductsList = this.resetProductsSource.asObservable();

  public isProductsUpdated = new BehaviorSubject(null);

  private productsListSourceForGallery = new BehaviorSubject(null);
  currentProductsListForGallery = this.productsListSourceForGallery.asObservable();

  private pageLoaded = new BehaviorSubject(null);
  pageLoadedStatus = this.pageLoaded.asObservable();

  utm_campaign:any = null;

  constructor(private http: HttpClient) { }

  passProducts(productData: string) {
    this.productsListSource.next(productData)
  }

  resetProducts() {
    this.resetProductsSource.next(null);
  }

  getProductFilters(): Observable<any> {
    let headers = new HttpHeaders({ 'Authorization': 'Basic ZnBhZG1pbjpmcHNlY3JldA==' });

    return this.http
      .get<any>(environment.adminV3Url + "api/fp/productfilters", { headers: headers } );

    // return of(productFilters);
  }

  public setFilters () {
    let filters = [
      {
        title: 'Colors',
        tag: undefined,
        filterOption: 'options.hex',
        isVisibleOnDesktop: false,
        isSelected: false,
        filterItems: [],
        showFilterItems: 15,
        minimumFiltersToShow: 15
      },
      {
        title: 'Type',
        tag: 'productType',
        isVisibleOnDesktop: true,
        isSelected: false,
        filterItems: [],
        showFilterItems: 5,
        minimumFiltersToShow: 5
      },
      {
        title: 'Brands',
        tag: 'brand',
        isVisibleOnDesktop: true,
        isSearchBarRequired: true,
        searchPlaceholder: "Search all brands",
        isSelected: false,
        filterItems: [],
        showFilterItems: 5,
        minimumFiltersToShow: 5
      },
      {
        title: 'Styles',
        tag: 'style',
        isVisibleOnDesktop: true,
        isSelected: false,
        filterItems: [],
        showFilterItems: 5,
        minimumFiltersToShow: 5
      },
      {
        title: 'Made for',
        tag: 'madeFor',
        isVisibleOnDesktop: true,
        isSelected: false,
        filterItems: [],
        showFilterItems: 5,
        minimumFiltersToShow: 5
      },
      {
        title: 'Price',
        tag: 'priceIndicator',
        isVisibleOnDesktop: true,
        isSelected: false,
        filterItems: [],
        showFilterItems: 5,
        minimumFiltersToShow: 5
      },
      {
        title: 'Season',
        tag: 'season',
        isVisibleOnDesktop: true,
        isSelected: false,
        filterItems: [],
        showFilterItems: 5,
        minimumFiltersToShow: 5
      }
    ];

    return filters;
  }

  setProductsData(data) {
    this.isProductsUpdated.next(data)
  }

  /**
   * Method to update the products for the product gallery
   * @param productData product list
   */
  passProductsForGallery(productData) {
    this.productsListSourceForGallery.next(productData)
  }

  setPageLoadStatus(status: string) {
    this.pageLoaded.next(status);
  }

  set campaignName(name: string){
    this.utm_campaign = name;
  }

  get campaignName(){
    return this.utm_campaign;
  }
}
