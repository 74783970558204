import { Injectable } from '@angular/core';
import { environment } from '@environment';
import * as mixpanel from 'mixpanel-browser';


@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  private mixpanelToken: string;

  /**
   * constructor
   * get mixpanel token and initialize
   */
  constructor() {
    this.mixpanelToken = environment.mixpanelToken;

    this.init();
  }

  /**
   * Initialize mixpanel.
   */
  init(): void {
    mixpanel.init(this.mixpanelToken, { persistence: "localStorage" });
  }


  /**
   * Create new Alias for user
   * Call this method only once in user lifecycle
   *
   * @param {string} alias
   */
  createAlias(alias: any) {
    mixpanel.alias(alias, mixpanel.get_distinct_id());
  }

  /** Identifies the user using the unique id generated by mixpanel for every user */
  identify(userEmail: string) {
    mixpanel.identify(userEmail);
  }

  /**
   * 
   * @param properties 
   */
  register(properties: any = {}): void {
    mixpanel.register(properties);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  } 

  /**
   * setup mixpannel
   *
   */
  setup() {
    this.setSuperProperties({ Platform: 'web' });
  }

 /**
   * setPeople
   * Store user profiles in Mixpanel's People Analytics product
   * @param {Object} properties
   */
  setPeople(properties: any = {}): void {
    mixpanel.people.set(properties);
  }

  /**
   * appendPeople
   * Store user profiles in Mixpanel's People Analytics product
   * @param {Object} properties
  */
  appendPeople(properties: any = {}): void {
    mixpanel.people.append(properties);
  }

  /**
   * 
   * @param property a property that needs to be incremented
   * The default increment value is 1.
   */
  incrementPeopleProperty(property: string) {
    mixpanel.people.increment(property);
  }

  /**
   * setSuperProperties
   *
   * @param {object} properties
   */
  setSuperProperties(properties) {
    mixpanel.register(properties);
  }

  /**
   * sendEvent
   *
   * @param {string} event
   * @param {object} properties
   */
  sendEvent(event: any, properties?) {
    if (properties) {
      mixpanel.track(event, properties);
    } else {
      this.trackEvent(event);
    }
  }

  /**
   * trackEvent
   * @param {String} event
   */
  trackEvent(event: any) {
    mixpanel.track(event);
  }

  /**
   * Reset Mixpanel
   */
  logout() {
    mixpanel.reset();
  }

  /**
   * appendPeople (Append multiple properties)
   * Store user profiles in Mixpanel's People Analytics product
   * @param {Object} properties
  */
   appendMultipleProperties(properties: any = {}): void {
    mixpanel.people.union(properties);
  }

  /**
   * Method to set user properties in Mixpanel if the property doesn't exist
   */
  setPeopleOnce(properties) {
    mixpanel.people.set_once(properties);
  }
}