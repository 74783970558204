import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '@environment';
import { AbTestsService } from 'angular-ab-tests';
import { CookieService } from 'ngx-cookie-service';
import { UtilityService } from './common/services/utility/utility.service';
import { ProductGalleryService } from './product-gallery/service/product-gallery.service';
declare const self: any;
declare let ga: Function;
const COOKIE_LIFETIME = 365;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'freshprints-web';
  campaign: any;

  constructor(public router: Router, private route: ActivatedRoute, private cookieService: CookieService, private utilitySvc: UtilityService, private abTestSvc: AbTestsService,
    private productGallerySvc: ProductGalleryService) {
    this.abTestSvc.setVersion('A', 'MGO-changes-test');
    this.cookieService.set('angular-ab-tests-MGO-changes-test', 'A');
    this.abTestSvc.setVersion('A', 'DT-CHANGES-TEST');
    this.cookieService.set('angular-ab-tests-DT-CHANGES-TEST', 'A');
    this.setUtmCookies();

    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (environment.googleAnalyticsDevMode) {
        if (event instanceof NavigationEnd && ga) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      }
    });
  }

  public setUtmCookies() {
    let url_utm_source = this.utilitySvc.getParameter("utm_source");
    let url_utm_medium = this.utilitySvc.getParameter("utm_medium");
    let url_utm_campaign = this.utilitySvc.getParameter("utm_campaign");
    let url_utm_content = this.utilitySvc.getParameter("utm_content");
    let url_utm_term = this.utilitySvc.getParameter("utm_term");

    if (url_utm_source) {
      this.cookieService.delete('utm_source');
      this.cookieService.set('utm_source', url_utm_source, { expires: COOKIE_LIFETIME });
    }

    if (url_utm_medium) {
      this.cookieService.delete('utm_medium');
      this.cookieService.set('utm_medium', url_utm_medium, { expires: COOKIE_LIFETIME });
    }

    if (url_utm_campaign) {
      this.cookieService.delete('utm_campaign');
      this.cookieService.set('utm_campaign', url_utm_campaign, { expires: COOKIE_LIFETIME });
    }

    if (url_utm_content) {
      this.cookieService.delete('utm_content');
      this.cookieService.set('utm_content', url_utm_content, { expires: COOKIE_LIFETIME });
    }

    if (url_utm_term) {
      this.cookieService.delete('utm_term');
      this.cookieService.set('utm_term', url_utm_term, { expires: COOKIE_LIFETIME });
    }
  }

  public ngAfterViewChecked() {
    // window['dataLayer'] = window['dataLayer'] || [];
    // window['dataLayer'].push({ 'event': 'optimize.activate' });
  }

  public ngOnInit() {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister();
      }
    });
  }
}
